@import "./mixins.scss";
@import "./ress.scss";
@import "./fonts.scss";
@import "./locomotive-scroll.scss";

body,
html {
  font-family: "HK Grotesk";
  color: #2a2a2a;
}

.title {
  font-weight: normal;
  line-height: 1.08;
  text-align: center;
  color: #2a2a2a;
  @include fluid(font-size, 600px, 1200px, 34px, 60px);
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.35;
  color: #2a2a2a;
  text-align: center;
}

.and-it-has-always-been {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.header {
  padding-top: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  max-width: 1440px;
  width: 100%;
  transform: translate3d(0, 0, 0.1px);
  z-index: 90;
  @include fluid(padding-right padding-left, 600px, 1200px, 20px, 100px);
  &--logo {
    display: block;
    padding: 15px 15px 15px 0;
    svg {
      @include fluid(width, 600px, 1200px, 80px, 128px);
    }
  }
  ul {
    display: flex;
    align-items: center;
    list-style: none;
  }
  &--cta {
    background: #DCDCDC;
    border-radius: 30px;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2a2a2a;
    text-decoration: none;
    padding: 20px 29px 19px;
    transition: all 0.22s ease;
    &:hover {
      background: #2a2a2a;
      color: #fff;
    }
  }
}

main {
  max-width: 1440px;
  width: 100%;
  @include fluid(padding-right padding-left, 600px, 1200px, 20px, 100px);
}

.top {
  padding-top: 30vh;
  margin-bottom: 200px;
  h1 {
    margin-bottom: 45px;
  }
  .text {
    margin: 0 auto;
    max-width: 520px;
  }
  picture {
    max-width: 800px;
    margin: 0 auto;
    display: block;
    img {
      display: block;
      max-width: 100%;
    }
  }
  h2 {
    margin-bottom: 45px;
    @include fluid(font-size, 600px, 1200px, 24px, 42px);
    line-height: 1.18;
  }
}

.form {
  .title {
    margin-bottom: 40px;
  }
  &--inner {
    width: 100%;
    display: block;
    width: 620px;
    max-width: 100%;
    border: 1px solid #2a2a2a;
    border-radius: 30px;
    margin: 0 auto;
    position: relative;
  }
  input {
    width: 77%;
    display: block;
    position: relative;
    z-index: 10;
    height: 60px;
    outline: none;
    padding: 0 20% 0 27px;
    font-size: 24px;
    font-family: inherit;
    @include placeholder {
      font-size: 16px;
    }
  }
  button {
    position: absolute;
    z-index: 20;
    transform: translate3d(0, 0, 0.1px);
    background: #2a2a2a;
    border-radius: 30px;
    width: 41%;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    transition: all 0.22s ease;
    &:hover {
      background: #dcdcdc;
      color: #2a2a2a;
    }
  }
}

.off {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 300px;
  h3 {
    margin-bottom: 24px;
  }
  .text {
    max-width: 520px;
    text-align: center;
  }
}

.center {
  picture {
    max-width: 950px;
    margin: 0 auto;
    width: 100%;
    display: block;
    img {
      display: block;
      max-width: 100%;
    }
  }
}

.features {
  text-align: center;
  @include fluid(padding-right padding-left, 600px, 1200px, 0px, 140px);
  margin-bottom: 300px;
  .title {
    margin-bottom: 17px;
  }
  .text {
    margin-bottom: 140px;
  }
  .row {
    picture {
      display: block;
      width: 100%;
      margin-bottom: 35px;
      border: 1px solid #2a2a2a;
      img {
        display: block;
        width: 100%;
      }
    }
    p {
      max-width: 450px;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      margin-bottom: 140px;
    }
  }
  .cols {
    width: 100%;
    display: flex;
    justify-content: space-between;
    picture {
      display: block;
      width: 100%;
      margin-bottom: 24px;
      border: 1px solid #2a2a2a;
      img {
        display: block;
        width: 100%;
      }
    }
    .col {
      @include fluid(width, 600px, 1200px, 47.5%, 42%);
      &:first-child {
        @include fluid(margin-right, 600px, 1200px, 5%, 16%);
      }
      .text {
        text-align: left;
      }
    }
  }
}

.clients {
  margin-top: 265px;
  margin-bottom: 300px;
  h4 {
    margin-bottom: 47px;
  }
  cite {
    @extend .text;
  }
  figure {
    width: 490px;
    max-width: 100%;
    margin: 0 auto;
    figcaption {
      margin: 20px 0;
    }
    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }
}

.footer {
  margin-top: 250px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 22px;
  line-height: 1.278;
  color: #2a2a2a;
  padding-left: 20px;
  padding-right: 20px;
  @include fluid(padding-bottom, 600px, 1200px, 30px, 60px);
  a {
    text-decoration: none;
    color: #8ed2db;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
